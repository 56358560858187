import './App.css';
import Personas from './personas.png';
import KirbyCart from './kirbycart.png';
import IterativeDesign from './iterative.png';
import SilkRoadsDesign from './silkroads.png';
import KirbyRide from './kirbyriding.gif';
import KirbyFloat from './Kirby emoji.png';
import Constellation from './pixelatednight.jpg';
import KirbySleeping from './kirby sleeping.png';
import Sun from './sun.gif';
import FakeResume from "./fakeresume.pdf";
import Myself from "./myself.JPG";
import Star from "./star.png";
import LinkedIn from "./LinkedIn.png";
import Soundcloud from "./Soundcloud.png";
import Email from "./Email.png";

function App() {
  return (
    <div className="App">
      <header className="App-header" style={{paddingTop: "-50rem"}}>
        <nav class="navbar navbar-expand-sm" style={{marginBottom: "1rem"}}>
          <div class="container-fluid">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" href="#">HOME</a>
              </li>
              {/* <li class="nav-item">
                <a class="nav-link" href="#">ABOUT</a>
              </li> */}
              <li class="nav-item">
                <a class="nav-link" href={FakeResume} target="_blank">RESUME</a>
              </li>
            </ul>
          </div>
        </nav>
        <div style={{ display: "flex", flexDirection: "row", marginTop: "2rem"}}>
        <img src={Myself} alt="a doodle of myself" style={{ height: "300px", width: "300px", borderRadius: "200px", marginLeft: "3rem", marginRight: "3rem"}}/>
        <div><h1>Hello! My name is <strong style={{ textDecoration: "underline overline dotted darkred" }}>[REDACTED]</strong>. &#128075;</h1>
        <p style={{ margin: "2rem", textAlign: "left", fontSize: "40px", lineHeight: "50px", fontWeight: "250" }}>
          I'm a college student from [REDACTED] foraying into user interface and design for the first time through CS1300. I really love being able to 
          explore my artistic creative juices and make people's experiences better at the same time. &#127752;
        </p>
        </div>
        </div>
        <p>
          Here are some things I've worked on in UI/UX. </p>
          <p>(Sun and Rainbow Kirby gifs from Deviantart! Will replace them after final portfolio review.)
        </p>
        
        <div className="gradient1" >
          <img src={Sun} alt="Smiling sun" style={{}}/>
          <img src={KirbyRide} alt="Kirby riding a star" style={{paddingTop: "30vh"}}/>
          <img src={KirbyFloat} alt="Kirby floating" style={{paddingTop: "10vh", marginLeft:"5rem", height: "10%", width: "10%"}}/>
        </div>
        <div style={{display:"flex", flexDirection:"row", alignContent: "center", justifyContent: "space-around", flexWrap: "wrap", backgroundColor:"lavenderblush", marginTop: "-300px"}}>
        <div className="section">
          <h4>Personas and Storyboarding Design:</h4>
          <div className="zoom">
            <a href="https://personas-and-storyboarding.pages.dev/">
              <img src={Personas} alt="Personas and Storyboarding Design" className="card"/>   
            </a>
          </div>
          <p className="contrast">In this project, I studied people's interactions with a certain interface 
          and observed what parts of the interface had what effects on the user.
          </p>
        </div>
        <div className="section">
          <h4>SilkRoads Website ReDesign:</h4>
          <div className="zoom">
            <a href="https://redesigned-silkroad.pages.dev/"><img src={SilkRoadsDesign} alt="SilkRoads Website ReDesign" className="card"/></a>
          </div>
          <p className="contrast">I took the SilkRoad organization's home page and redesigned it to be less cluttered and more mobile-friendly to users browsing the website.</p>
        </div>
        <div className="section">
          <h4>Online Shopping Cart Development Project:</h4>
          <div className="zoom">
            <a href="https://development-project.pages.dev/"><img src={KirbyCart} alt="Kirby Online Shopping Cart Dev Project" className="card"/></a>
          </div>
          <p className="contrast">I learned how to better utilize React for an interactive webpage experience, and tried out my newly learned knowledge on creating an online shopping cart for Kirby merchandise.</p>
        </div>
        <div className="section">
          <h4>Iterative Design Group Project:</h4>
          <div className="zoom">
            <a href="https://productive-antelope-304.github.io/iterative-design/"><img src={IterativeDesign} alt="Iterative Design Group Project" className="card"/></a>
          </div>
          <p className="contrast">I worked with 3 other classmates to create a Figma prototype for Pills2Me's medicine delivery service. We created a design, received feedback on it from peers, and reworked it to be more user-friendly.</p>
        </div>
        </div>
      </header>
      <div className="gradient2" style={{display: "flex", flexDirection: "row"}}>
        <img src={Star} alt="A star" style={{width:"5%", height:"5%", marginLeft: "2rem", marginRight: "9rem", marginTop: "5rem"}}/>
        <img src={Star} alt="A star" style={{width:"5%", height:"5%", marginRight: "9rem", marginTop: "20rem"}}/>
        <img src={Star} alt="A star" style={{width:"5%", height:"5%", marginRight: "9rem", marginTop: "5rem"}}/>
        {/* <img src={KirbySleeping} alt="Kirby Sleeping" style={{width: "30%", height: "30%"}}/> */}
        <img src={Star} alt="A star" style={{width:"5%", height:"5%", marginRight: "9rem", marginTop: "20rem"}}/>
        <img src={Star} alt="A star" style={{width:"5%", height:"5%", marginRight: "9rem", marginTop: "5rem"}}/>
        <img src={Star} alt="A star" style={{width:"5%", height:"5%", marginRight: "9rem", marginTop: "20rem"}}/>
        <img src={Star} alt="A star" style={{width:"5%", height:"5%", marginRight: "9rem", marginTop: "5rem"}}/>
        {/* <p style={{color: "white"}}>From PixilArt (will replace with a drawing of myself)</p> */}
      </div>
      {/* <img src={Constellation} alt="A pixelated night sky" style={{width: "100%"}}/> */}
      <footer>
        <h1 style={{ margin: "1rem"}}>You can reach me at: </h1> 
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <a className="cutelink" href="https://www.linkedin.com/" style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
            <img src={LinkedIn} alt= "LinkedIn icon" style={{height: "400px", width: "400px"}}/><p style={{ marginTop: "-7rem", paddingBottom:"5rem"}}>LinkedIn</p>
            </a>
            <a className="cutelink" href="https://soundcloud.com/nemodan" style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
            <img src={Soundcloud} alt= "Soundcloud icon" style={{height: "400px", width: "400px"}}/><p style={{ marginTop: "-7rem", paddingBottom:"5rem"}}>SoundCloud</p>
            </a>
            <a className="cutelink" href="mailto:example@gmail.com" style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
            <img src={Email} alt= "Mail icon" style={{height: "400px", width: "400px"}}/><p style={{ marginTop: "-7rem", paddingBottom:"5rem"}}>Email</p>
            </a>
        </div>
        {/* <p style={{ margin: "1rem", marginLeft: "43rem"}}><a href="https://www.freepik.com/free-vector/urban-background-with-buildings_13561295.htm#query=pixel%20art&position=4&from_view=keyword">Image by pikisuperstar</a> on Freepik</p> */}
      </footer>
    </div>
  );
}

export default App;
